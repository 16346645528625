const { sharedCSPDirectives } = require("config/utils");

module.exports = {
  default_image_url: "https://placehold.it/150x220",
  available_page_sizes: [16, 16 * 2, 16 * 4],
  mapsKey: "AIzaSyB451F91Eed54R7ToFu8_tzH-0IMyN66YI",
  color_attribute_id: "93",
  website_id: 1,
  tax: 1,
  contentSecurityPolicy: {
    directives: sharedCSPDirectives({
      scriptSrc: ["js.stripe.com"],
      frameSrc: ["js.stripe.com"],
      styleSrc: [],
      imgSrc: [],
      connectSrc: [],
      baseUri: [],
    }),
  },
  homepage_identifier: "home",
  defaultTitle: "Front-Commerce",
  defaultDescription:
    "Site de démonstration d'une plateforme e-commerce dynamique et épurée.",
  themeColor: "#666699",
  search: {
    dynamicFacetSize: 10,
    ignoredAttributeKeys: [
      "url_key",
      "description",
      "short_description",
      "name",
      "image",
      "status",
      "tax_class_id",
    ],
    authorizedCategoriesFacet: [],
  },
  phoneNumber: "05 61 40 54 92",
  email: "contact@front-commerce.com",
  rewrittenToRoot: ["/home"],
  useUserTitles: false,
  maxAddressLength: 38,
  cookieMaxAgeInMonths: 13,
};
