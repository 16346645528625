import React from "react";
import loadable from "@loadable/component";
import LoadingRoute from "theme/modules/Router/LoadingRoute";

export default [
  {
    path: "/checkout",
    layout: loadable(
      () =>
        import(
          "/project/node_modules/front-commerce/src/web/theme/routes/checkout/_layout.js"
        ),
      { fallback: <LoadingRoute /> }
    ),
    error: loadable(
      () =>
        import(
          "/project/node_modules/front-commerce/src/web/theme/routes/checkout/_error.js"
        ),
      { fallback: <LoadingRoute /> }
    ),
    routes: [
      {
        path: "/checkout/",
        exact: true,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/checkout/index.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/checkout/negotiable-quotes/:id",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/checkout/negotiable-quotes/[id].js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/checkout/login",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/checkout/login.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/checkout/success",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/checkout/success.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
    ],
  },
  {
    layout: loadable(
      () =>
        import(
          "/project/node_modules/front-commerce/src/web/theme/routes/_layout.js"
        ),
      { fallback: <LoadingRoute /> }
    ),
    error: loadable(
      () =>
        import(
          "/project/node_modules/front-commerce/src/web/theme/routes/_error.js"
        ),
      { fallback: <LoadingRoute /> }
    ),
    routes: [
      {
        path: "/",
        exact: true,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/index.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/wishlist/share/:shareCode/",
        exact: true,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/wishlist/share/[shareCode]/index.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/wishlist/share/:shareCode/add/",
        exact: true,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/wishlist/share/[shareCode]/add/index.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/wishlist/share/:shareCode/add/:itemId",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/wishlist/share/[shareCode]/add/[itemId].js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/account/password-request",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/account/password-request.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/account/password-reset",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/account/password-reset.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/category/:id",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/category/[id].js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/orders/",
        exact: true,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/orders/index.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/product/:id",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/product/[id].js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/search/:query",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/search/[query].js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/cart",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/cart.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/contact",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/contact.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/login",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/login.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/newsletter",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/newsletter.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/quick-order",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/modules/magento2-b2b-demo/web/theme/routes/quick-order.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/register",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/register.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
      {
        path: "/user",
        layout: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/user/_inner-layout.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
        error: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/user/_error.js"
            ),
          { fallback: <LoadingRoute /> }
        ),
        routes: [
          {
            path: "/user/",
            exact: true,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/index.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/orders/",
            exact: true,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/orders/index.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/orders/:orderId/",
            exact: true,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/orders/[orderId]/index.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/orders/:orderId/invoice/:invoiceId/",
            exact: true,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/orders/[orderId]/invoice/[invoiceId]/index.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/orders/:orderId/returns/create",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/orders/[orderId]/returns/create.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/orders/:orderId/refunds",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/orders/[orderId]/refunds.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/company/",
            exact: true,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/user/company/index.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/company/credit",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/user/company/credit.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/company/structure",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/user/company/structure.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/company/users",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/user/company/users.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/negotiable-quotes/",
            exact: true,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/user/negotiable-quotes/index.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/negotiable-quotes/:id",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/user/negotiable-quotes/[id].js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/requisition-lists/",
            exact: true,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/user/requisition-lists/index.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/requisition-lists/:id",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/modules/front-commerce-b2b/web/theme/routes/user/requisition-lists/[id].js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/address-book",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/address-book.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/downloadable-products",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/downloadable-products.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/information",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/information.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/logout",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/theme-chocolatine/web/theme/routes/user/logout.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/newsletter",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/newsletter.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/returns",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/returns.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/store-credit",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/store-credit.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
          {
            path: "/user/wishlist",
            exact: false,
            component: loadable(
              () =>
                import(
                  "/project/node_modules/front-commerce/src/web/theme/routes/user/wishlist.js"
                ),
              { fallback: <LoadingRoute /> }
            ),
          },
        ],
      },
      {
        path: "/:slug",
        exact: false,
        component: loadable(
          () =>
            import(
              "/project/node_modules/front-commerce/src/web/theme/routes/[slug].js"
            ),
          { fallback: <LoadingRoute /> }
        ),
      },
    ],
  },
];
