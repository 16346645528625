import { BsLightningFill } from "react-icons/bs";

/** @type {Record<string, import("react-icons").IconType>} */
const componentIcons = {
  /** Overrided this file to add lightning icon to be used for quick order link in the top menu */
  lightning: BsLightningFill,
};

/** @type {string[]} */
const svgIcons = [];

export default {
  componentIcons,
  svgIcons,
};
