/** A simple config helper to ensure all directives are included */
export const sharedCSPDirectives = (userDirectives) => {
  const staticDirectives = {
    scriptSrc: ["https://*.googletagmanager.com"],
    frameSrc: [],
    styleSrc: [],
    imgSrc: [
      "https://*.google-analytics.com",
      "https://*.googletagmanager.com",
      "https://placekitten.com", // for the styleguide for instance https://magento2.front-commerce.app/styleguide/?path=/story/modules-organisms-lightbox--with-one-image
    ],
    connectSrc: [
      "https://*.google-analytics.com",
      "https://*.analytics.google.com",
      "https://*.googletagmanager.com",
    ],
    baseUri: [],
  };

  const select = (directiveName) => {
    const userDirective = userDirectives?.[directiveName] || [];
    const staticDirective = staticDirectives?.[directiveName] || [];
    return [...new Set([...userDirective, ...staticDirective])];
  };

  return {
    scriptSrc: select("scriptSrc"),
    frameSrc: select("frameSrc"),
    styleSrc: select("styleSrc"),
    imgSrc: select("imgSrc"),
    connectSrc: select("connectSrc"),
    baseUri: select("baseUri"),
  };
};
